import React from 'react';
import numeral from 'numeral';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { empty } from './../../../utils';

const Summary = (props) => {
    const handleChangeField = (field, event) => {
        props.setField(field, event.target.value);
    };

    const paypalOptions = {
        'client-id': 'AdSAQQvHzPa8MqdFY_B8-mXnY2J63ZHYwsCC_UhDZ6VMwE3pyKeF2bny0xxnStiLXwArhJ3vjlv_ehNi',
        'buyer-country': 'US',
        'locale': 'en_US',
    }

    const handleCreateOrder = (data, actions) => {
        return actions.order.create({
            purchase_units: [
                {
                    amount: {
                        currency_code: 'USD',
                        value: props.total.toFixed(2),
                    },
                },
            ],
        });
    };

    const handleOnApprove = (data, actions) => {
        return actions.order.capture().then(function (details) {
            props.placeOrder(details.id);
        });
    };

    const handlePlaceOrder = () => {
        props.placeOrder();
    };

    let placeOrderButton = (
        <button onClick={handlePlaceOrder} disabled={props.placingOrder}>
            {props.placingOrder ? <i className="fas fa-circle-notch fa-spin" /> : 'PLACE ORDER'}
        </button>
    );
    if (props.paymentMethod === 'Paypal') {
        placeOrderButton = (
            <PayPalScriptProvider options={paypalOptions}>
                <PayPalButtons
                    forceReRender={[props.total]}
                    style={{ layout: 'vertical' }}
                    createOrder={handleCreateOrder}
                    onApprove={handleOnApprove}
                />
            </PayPalScriptProvider>
        );
    }

    let discount = 0;
    let couponDiscount = null;
    if (!empty(props.coupon)) {
        discount = props.totalPrinting * props.coupon.discount;
        couponDiscount = (
            <div className="price-row">
                <div className="label">Coupon Discount:</div>
                <div className="value">-{numeral(discount).format('$0,0.00')}</div>
            </div>
        );
    }

    return (
        <div className="summary-container">
            <div className="cart-summary">
                <div className="title">Cart Summary</div>
                <div className="coupon">
                    <div className="label">Coupon:</div>
                    <input type="text" value={props.fields.coupon} onChange={handleChangeField.bind(this, 'coupon')} />
                    <button onClick={props.validateCoupon} disabled={props.validatingCoupon}>
                        {props.validatingCoupon ? <i className="fas fa-circle-notch fa-spin" /> : 'APPLY'}
                    </button>
                </div>
                <div className="details">
                    <div className="price-row">
                        <div className="label">Total of Jobs:</div>
                        <div className="value">{props.count}</div>
                    </div>
                    <div className="price-row">
                        <div className="label">Total of Printing:</div>
                        <div className="value">{numeral(props.totalPrinting).format('$0,0.00')}</div>
                    </div>
                    {couponDiscount}
                    <div className="price-row">
                        <div className="label">Total of Shipping:</div>
                        <div className="value">{numeral(props.totalShipping).format('$0,0.00')}</div>
                    </div>
                    <div className="price-row">
                        <div className="label">Total of Tax:</div>
                        <div className="value">{numeral(props.tax).format('$0,0.00')}</div>
                    </div>
                    <div className="price-row grand-total-row">
                        <div className="label">Grand Total:</div>
                        <div className="value">{numeral(props.total).format('$0,0.00')}</div>
                    </div>
                </div>
            </div>
            <div className="checkout-button">
                {placeOrderButton}
            </div>
        </div>
    );
}

export default Summary;
