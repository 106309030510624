import React, { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Table from './Table';
import Summary from './Summary';
import BillingAddressModal from './BillingAddressModal';
import { empty, stringifyEqualityCheck } from './../../utils';
import * as selectors from './Checkout.selectors';
import * as loginSelectors from './../Login/Login.selectors';
import * as actions from './Checkout.actions';
import Layout from './../Layout';
import { Helmet } from 'react-helmet';
import './Checkout.scss';
import { Redirect } from 'react-router-dom';
import * as dialog from './../common/dialog';

const Checkout = (props) => {
    /* State to props */
    const items = useSelector(selectors.getItems, stringifyEqualityCheck);
    const fetchingItems = useSelector(selectors.getFetchingItems);
    const placingOrder = useSelector(selectors.getPlacingOrder);
    const fields = useSelector(selectors.getFields, stringifyEqualityCheck);
    const savingBillingAddress = useSelector(selectors.getSavingBillingAddress);
    const billingAddresses = useSelector(selectors.getBillingAddresses, stringifyEqualityCheck);
    const fetchingBillingAddresses = useSelector(selectors.getFetchingBillingAddresses, stringifyEqualityCheck);
    const addressFields = useSelector(selectors.getAddressFields, stringifyEqualityCheck);
    const selectedAddress = useSelector(selectors.getSelectedAddress, stringifyEqualityCheck);
    const information = useSelector(selectors.getInformation, stringifyEqualityCheck);
    const paymentMethod = useSelector(selectors.getPaymentMethod);
    const validatingCoupon = useSelector(selectors.getValidatingCoupon);
    const coupon = useSelector(selectors.getCoupon, stringifyEqualityCheck);
    const user = useSelector(loginSelectors.getUser);

    /* Dispatch to props */
    const dispatch = useDispatch();
    const fetchItems = useCallback(() => dispatch(actions.fetchItems()), [dispatch]);
    const placeOrder = useCallback((paypalId) => dispatch(actions.placeOrder(paypalId)), [dispatch]);
    const setField = useCallback((field, value) => dispatch(actions.setField(field, value)), [dispatch]);
    const setSelectedAddress = useCallback((value) => dispatch(actions.setSelectedAddress(value)), [dispatch]);
    const fetchBillingAddresses = useCallback(() => dispatch(actions.fetchBillingAddresses()), [dispatch]);
    const setAddressFields = useCallback((value) => dispatch(actions.setAddressFields(value)), [dispatch]);
    const setAddressField = useCallback((field, value) => dispatch(actions.setAddressField(field, value)), [dispatch]);
    const saveBillingAddress = useCallback(() => dispatch(actions.saveBillingAddress()), [dispatch]);
    const fetchInformation = useCallback(() => dispatch(actions.fetchInformation()), [dispatch]);
    const setPaymentMethod = useCallback((value) => dispatch(actions.setPaymentMethod(value)), [dispatch]);
    const validateCoupon = useCallback(() => dispatch(actions.validateCoupon()), [dispatch]);
    const setHistory = useCallback((history) => dispatch(actions.setHistory(history)), [dispatch]);
    const resetState = useCallback(() => dispatch(actions.resetState()), [dispatch]);
    const showBillingAddressModal = useCallback(() => dispatch(dialog.actions.showDialog('frontend-checkout-billing-address-modal')), [dispatch]);
    const hideBillingAddressModal = useCallback(() => dispatch(dialog.actions.hideDialog('frontend-checkout-billing-address-modal')), [dispatch]);

    /* componentDidMount */
    useEffect(() => {
        window.scrollTo(0, 0);
        document.body.classList.remove('no-scroll');

        setHistory(props.history);
        fetchItems();
        fetchInformation();

        return () => {
            resetState();
        };
    }, [setHistory, resetState, fetchItems, fetchInformation, props.history]);

    if (user == null) {
        return <Redirect to="/" />;
    }

    let totalPrinting = 0;
    let totalShipping = 0;
    let count = 0;

    for (const item of items) {
        if (!item.checkout) {
            continue;
        }

        count++;
        const pricing = JSON.parse(item.pricing);

        totalPrinting += pricing.printing;
        totalShipping += pricing.shipping;
    }

    let discount = 0;
    if (!empty(coupon)) {
        discount = totalPrinting * coupon.discount;
    }

    let billingAddress = null;
    if (items.length > 0) {
        billingAddress = items[0].billingAddress;
    }

    let tax = (totalPrinting - discount) * 0.0825;
    if (billingAddress != null && billingAddress.is_reseller) {
        tax = 0;
    }

    let total = totalPrinting + totalShipping + tax - discount;

    return (
        <Layout history={props.history}>
            <Helmet>
                <title>Checkout - OvernightColor.com | Wholesale Online Print Services</title>
            </Helmet>
            <div className="checkout-container">
                <div className="content-container">
                    <Table
                        items={items}
                        fetchingItems={fetchingItems}
                        fields={fields}
                        total={total}
                        paymentMethod={paymentMethod}
                        setField={setField}
                        showBillingAddressModal={showBillingAddressModal}
                        fetchBillingAddresses={fetchBillingAddresses}
                        setSelectedAddress={setSelectedAddress}
                        setPaymentMethod={setPaymentMethod}
                    />
                    <Summary
                        items={items}
                        placingOrder={placingOrder}
                        fields={fields}
                        count={count}
                        totalPrinting={totalPrinting}
                        totalShipping={totalShipping}
                        tax={tax}
                        total={total}
                        paymentMethod={paymentMethod}
                        validatingCoupon={validatingCoupon}
                        coupon={coupon}
                        placeOrder={placeOrder}
                        setField={setField}
                        validateCoupon={validateCoupon}
                    />
                </div>
                <BillingAddressModal
                    billingAddresses={billingAddresses}
                    addressFields={addressFields}
                    fetchingBillingAddresses={fetchingBillingAddresses}
                    selectedAddress={selectedAddress}
                    savingBillingAddress={savingBillingAddress}
                    information={information}
                    hideBillingAddressModal={hideBillingAddressModal}
                    setSelectedAddress={setSelectedAddress}
                    setAddressField={setAddressField}
                    setAddressFields={setAddressFields}
                    saveBillingAddress={saveBillingAddress}
                />
            </div>
        </Layout>
    );
};

export default Checkout;
